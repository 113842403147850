import GraphQlMixin from '@/mixins/graphql.mixin'

export default {
  props: {
    label: { type: String, default: 'Assignee' },
    multiple: { type: Boolean, default: false },
    selectedAssignees: Object | Array,
    disabled: { type: Boolean },
    dense: { type: Boolean },
    hideDetails: { type: Boolean },
    defaultTarget: { type: Array, default: () => ['user', 'organization'] },
    targetDisabled: { type: Boolean, default: false }
  },
  mixins: [GraphQlMixin],
  data() {
    return {
      assignees: [],
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      target: ['user', 'organization']
    }
  },
  computed: {
    validSearch() {
      return this.search && this.search.length >= 3
    },
    searchUserOnly() {
      return _.isEqual(this.target, ['user'])
    },
    searchOrgOnly() {
      return _.isEqual(this.target, ['organization'])
    },
    query() {
      if (this.searchUserOnly) {
        const fields = this.getFieldsGql('read', 'IntraAccount', ['id', 'name', 'samAccountName', 'employeeId'])
        return this.$gql`
          query SearchIntraAccounts($q: String!, $take: Int) {
            intraAccounts(search: $q, take: $take, where: [{ path: "deletedExternally", value: "false" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      } else if (this.searchOrgOnly) {
        const fields = this.getFieldsGql('read', 'Organization', ['id', 'name', 'externalId'])
        return this.$gql`
          query SearchOrganizations($q: String!, $take: Int) {
            organizations(search: $q, take: $take, where: [{ path: "deletedExternally", value: "false" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      } else {
        const fields = this.getFieldsGql('read', 'Assignee', [
          'id',
          'name',
          { type: 'IntraAccount', fields: ['samAccountName', 'employeeId'], isFragment: true },
          { type: 'Organization', fields: ['externalId'], isFragment: true }
        ])
        return this.$gql`
          query SearchAssignees($q: String!, $take: Int) {
            assignees(search: $q, take: $take, where: [{ path: "deletedExternally", value: "false" }], orderBy: [{ path: "name" }]) {
              items {
                ${fields}
              }
            }
          }
        `
      }
    }
  },
  watch: {
    search() {
      if (this.validSearch) {
        this.debouncing = true
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    selectedAssignees() {
      this.updateSelectedAssignees()
    },
    select(o) {
      // clear the query if the searcher is not multiple
      // clear the query if the searcher is multiple, but all options are selected
      if (!this.multiple || _.differenceBy(this.assignees, this.select, 'id').length === 0) {
        this.search = ''
      }
      this.$emit('update:assigneeId', _.get(o, 'id'))
      this.$emit('update:selectedAssignees', o)
    }
  },
  methods: {
    updateSelectedAssignees() {
      if (!_.isEmpty(this.selectedAssignees)) {
        if (Array.isArray(this.selectedAssignees)) {
          this.assignees = [...this.assignees, ...this.selectedAssignees]
        } else {
          this.assignees.push(this.selectedAssignees)
        }
        this.assignees = _.uniqBy(this.assignees, 'id')
        this.select = this.selectedAssignees
      }
    }
  },
  apollo: {
    assignees: {
      query() {
        return this.query
      },
      variables() {
        return { q: this.search, take: 10 }
      },
      skip() {
        return !this.validSearch || this.debouncing
      },
      update({ intraAccounts, organizations, assignees }) {
        intraAccounts = _.get(intraAccounts, 'items', [])
        organizations = _.get(organizations, 'items', [])
        assignees = _.get(assignees, 'items', [])
        let result = [..._.toArray(intraAccounts), ..._.toArray(organizations), ..._.toArray(assignees)]
        if (!_.isEmpty(this.select)) {
          if (Array.isArray(this.select)) {
            return result.concat(this.select)
          } else {
            result.push(this.select)
          }
        }

        return result
      },
      result({ error }) {
        // TODO: refactor error handling
        if (error) {
          console.error(error)
        }
      }
    }
  },
  created() {
    this.updateSelectedAssignees()
    this.target = this.defaultTarget
  }
}
